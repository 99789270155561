import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function loadUser(email) {
    try {
        const userRef = doc(db, "users", email);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const userInFs = { ...userSnap.data(), id: userSnap.id };
            return userInFs;
        } else {
            console.log("Cannot find user ", email);
            notification("Error", `Cannot find user ${email}`, "danger");
            return null;
        }
    } catch (err) {
        console.log(err);
    }
}
