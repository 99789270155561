import React, { useState } from "react";

export const UsersContext = React.createContext();

export const UsersProvider = ({ children }) => {
    const [users, setUsers] = useState();
    const [unsubscribeUsers, setUnsubscribeUsers] = useState(() => () => {});

    return (
        <UsersContext.Provider value={{ users, setUsers, unsubscribeUsers, setUnsubscribeUsers }}>
            {children}
        </UsersContext.Provider>
    );
};
