import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function ConfirmationDialog({ open, handleClose, title, message }) {
    return (
        <Dialog open={open} onClose={() => handleClose()} PaperProps={{ sx: { position: "absolute", top: 100 } }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="error">
                    Cancel
                </Button>
                <Button onClick={() => handleClose("Ok")} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
